@font-face {
	font-family: 'Overpass';
	font-weight: 400;
	font-style: normal;
	src: url('/fonts/Overpass-Regular.woff2');
	src: url('/fonts/Overpass-Regular.woff'); }

@font-face {
	font-family: 'Overpass';
	font-weight: 600;
	font-style: normal;
	src: url('/fonts/Overpass-SemiBold.woff2');
	src: url('/fonts/Overpass-SemiBold.woff'); }

@font-face {
	font-family: 'Overpass';
	font-weight: 800;
	font-style: normal;
	src: url('/fonts/Overpass-Black.woff2');
	src: url('/fonts/Overpass-Black.woff'); }
